import { useState } from 'react';
import { MultiValue } from 'react-select';
import { RecoilState, useRecoilState, useResetRecoilState } from 'recoil';

import { HiOutlineFilter } from 'react-icons/hi';

import Button from '../atoms/Button';
import SelectInput from '../atoms/Select';

import { format, subMonths } from 'date-fns';
import Datepicker from 'react-tailwindcss-datepicker';
import { DateValueType } from 'react-tailwindcss-datepicker/dist/types';
import { twMerge } from 'tailwind-merge';

interface SideFilterProps {
  filters: {
    key: string;
    name: string;
    values: {
      value: any;
      label: string;
    }[];
    asyncFn?: any;
    type?: string;
  }[];
  atom: RecoilState<any>;
  applyChanges?: (validation?: boolean) => void;
  children?: React.ReactNode;
  floatButtonClassName?: string;
}

const SideFilter: React.FC<SideFilterProps> = ({
  filters,
  atom,
  applyChanges,
  children,
  floatButtonClassName
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filtersData, setFiltersData] = useRecoilState(atom);
  const resetFilters = useResetRecoilState(atom);

  const handleOpenFilter = () => {
    setIsOpen(old => !old);
  };

  const SelectOption = (key: string) => {
    return (selectedOptions: MultiValue<any>) => {
      setFiltersData((prevFilters: any) => {
        return {
          ...prevFilters,
          [key]: selectedOptions.map(option => option.value)
        };
      });
    };
  };

  const handleResetFilters = () => {
    resetFilters();
    setIsOpen(false);
    applyChanges?.(true);
  };

  const handleApplyFilters = () => {
    setIsOpen(false);
    applyChanges?.(false);
  };

  const today = new Date();

  return (
    <div
      className={`fixed h-screen w-64 top-0 bg-primary ${
        isOpen ? 'right-0' : '-right-64'
      } transition-all duration-500 z-[12] no-print`}
    >
      <div className="flex flex-col relative w-full h-full pl-6 pr-8 py-12">
        <button
          type="button"
          className={twMerge(
            'absolute -left-8 top-16 bg-primary p-2 py-3 pr-4 rounded-lg',
            floatButtonClassName ?? ''
          )}
          onClick={handleOpenFilter}
        >
          {Object.keys(filtersData).reduce(
            (acc, key) => filtersData[key].length > 0 || acc,
            false
          ) ? (
            <HiOutlineFilter className="w-5 h-5 fill-white" color="white" />
          ) : (
            <HiOutlineFilter className="w-5 h-5" color="white" />
          )}
        </button>

        <h1 className="font-bold text-2xl text-white mb-6">Filtros</h1>

        <div className="space-y-2">
          {filters.find(it => it.key == 'timeRange') && (
            <div>
              <h3 className="text-sm font-bold text-white">
                {filters.find(it => it.key == 'timeRange')?.name}
              </h3>
              <Datepicker
                popoverDirection="down"
                value={filtersData?.['timeRange'] as DateValueType}
                showShortcuts={true}
                configs={
                  {
                    shortcuts: {
                      today: 'Hoje',
                      yesterday: 'Ontem',
                      past: (period: number) => `Últimos ${period} dias`,
                      last60Days: {
                        text: 'Últimos 60 dias',
                        period: {
                          start: format(subMonths(new Date(), 2), 'yyyy-MM-dd'),
                          end: format(new Date(), 'yyyy-MM-dd')
                        }
                      },
                      thisYear: {
                        text: 'Esse ano',
                        period: {
                          start: `${today.getFullYear()}-01-01`,
                          end: `${today.getFullYear()}-12-31`
                        }
                      }
                    },
                    footer: {
                      cancel: 'CText',
                      apply: 'AText'
                    }
                  } as any
                }
                primaryColor={'sky'}
                onChange={(val: any) =>
                  setFiltersData((prevFilters: any) => ({
                    ...prevFilters,
                    timeRange: val
                  }))
                }
                i18n="pt-br"
                placeholder="Selecione uma data"
                inputClassName={
                  'relative transition-all duration-300 h-[36px] pl-2 pr-10 w-full border-[1px] border-[#cccccc] dark:border-slate-600 rounded-[10px] tracking-wide font-light text-sm text-white placeholder-white bg-primary focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-sky-500 stroke-white focus:ring-sky-500/20'
                }
                // maxDate={new Date()}
                classNames={{
                  toggleButton() {
                    return 'absolute right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed stroke-white svg-white';
                  }
                }}
              />
            </div>
          )}

          {children}

          <div className="flex flex-col gap-2 max-h-[calc(100dvh-350px)] overflow-auto scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full">
            {filters
              .filter(it => it.key !== 'timeRange')
              .map(filter =>
                filter.type === 'fromTo' ? (
                  <div key={filter.key} className="flex flex-col">
                    <h3 className="text-sm font-bold text-white">
                      {filter.name}
                    </h3>

                    <div className="flex gap-2">
                      <input
                        className="h-9 w-full border border-px border-white rounded-lg px-3 bg-transparent text-white font-medium placeholder-white placeholder:font-thin"
                        value={filtersData?.[filter.key].from}
                        onChange={e => {
                          setFiltersData((prevFilters: any) => {
                            return {
                              ...prevFilters,
                              [filter.key]: {
                                ...prevFilters[filter.key],
                                from: e.target.value
                              }
                            };
                          });
                        }}
                        placeholder="De"
                      />
                      <input
                        className="h-9 w-full border border-px border-white rounded-lg px-3 bg-transparent text-white font-medium placeholder-white placeholder:font-thin"
                        value={filtersData?.[filter.key].to}
                        onChange={e => {
                          setFiltersData((prevFilters: any) => {
                            return {
                              ...prevFilters,
                              [filter.key]: {
                                ...prevFilters[filter.key],
                                to: e.target.value
                              }
                            };
                          });
                        }}
                        placeholder="Até"
                      />
                    </div>
                  </div>
                ) : filter.type === 'inputText' ? (
                  <div key={filter.key} className="flex flex-col">
                    <h3 className="text-sm font-bold text-white">
                      {filter.name}
                    </h3>

                    <input
                      className="h-9 w-full border border-px border-white rounded-lg px-3 bg-transparent text-white font-medium placeholder-white placeholder:font-thin"
                      value={filtersData?.[filter.key]}
                      onChange={e => {
                        setFiltersData((prevFilters: any) => ({
                          ...prevFilters,
                          [filter.key]: e.target.value
                        }));
                      }}
                      placeholder={filter.name}
                    />
                  </div>
                ) : (
                  <div key={filter.key} className="flex flex-col">
                    <h3 className="text-sm font-bold text-white">
                      {filter.name}
                    </h3>

                    <SelectInput
                      onChange={SelectOption(filter.key)}
                      options={filter.values}
                      value={
                        filter.asyncFn
                          ? filtersData?.[filter.key]?.map((it: any) => ({
                              value: it,
                              label: it
                            }))
                          : filters
                              .find((it: any) => it.key == filter.key)
                              ?.values.filter((it: any) =>
                                filtersData?.[filter.key]?.includes?.(it.value)
                              )
                      }
                      asyncFn={filter.asyncFn}
                    />
                  </div>
                )
              )}
          </div>
        </div>

        {applyChanges && filters.length !== 0 && (
          <>
            <Button
              title="Aplicar Filtros"
              className="bg-white hover:bg-slate-300 text-primary mt-6 w-full"
              classNameSpan="text-primary font-bold"
              onClick={handleApplyFilters}
            />

            <div className="flex w-full justify-center mt-2">
              <a
                className="underline text-white hover:cursor-pointer"
                onClick={handleResetFilters}
              >
                Limpar filtros
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SideFilter;
