import { equipment } from '../arrays/equipment';
import { objectify } from './WorflowTests';

export const PriorityPageFilter = [
  {
    type: 'inputText',
    name: 'Ordem',
    key: 'ordem',
    values: []
  },
  {
    name: 'Status Ordem',
    key: 'statusOrdem',
    values: ['Não Concluída', 'Pendente', 'Concluída', 'Delineada']
      .sort((a, b) => a.localeCompare(b))
      .map(objectify)
  },
  {
    type: 'inputText',
    name: 'Nota',
    key: 'nota',
    values: []
  },
  {
    type: 'inputText',
    name: 'Descrição Breve',
    key: 'descricaoBreve',
    values: []
  },
  {
    name: 'Estação',
    key: 'estacao',
    values: [
      'FURADO - CAMPO',
      'FURADO - ESTAÇÃO',
      'FURADO - O&M COMPRESSÃO',
      'FURADO - SUBESTAÇÃO',
      'PILAR - CAMPO',
      'PILAR - ESTAÇÃO',
      'PILAR - FLUIDOS',
      'PILAR - LABORATÓRIO',
      'PILAR - O&M COMPRESSÃO',
      'PILAR - P16',
      'PILAR - SUBESTAÇÃO',
      'UPGN'
    ]
      .sort((a, b) => a.localeCompare(b))
      .map(objectify)
  },
  {
    key: 'equipamentos',
    name: 'Equipamentos',
    values: equipment.sort((a, b) => a.localeCompare(b)).map(objectify)
  },
  {
    key: 'cenarioDePriorizacao',
    name: 'Cenário de Priorização',
    values: [
      'Risco Grave e Iminente',
      'Incremento de Produção: Planejamento SPT',
      'Incremento Produção: Demais Op. em Poços',
      'Retorno Operacional',
      'Manut Produção: Corretivas Equipamentos',
      'Corretivas Atendimento Legal/Regulatório',
      'Atendimento Legal/Regulatório',
      'Manut Produção: 30 maiores Poços e Injet',
      'Manut Produção: Demais Poços e Ativid',
      'Incremento Produção: SPT Extra',
      'Demais Equipamentos e Sistemas em Oper',
      'Melhorias Operacionais'
    ]
      .sort((a, b) => a.localeCompare(b))
      .map(objectify)
  },
  {
    key: 'tipoDeAtividade',
    name: 'Tipo de Atividade',
    values: [
      'Ordem de Inspeção',
      'Ordem de Tratamento de RTI',
      'Ordem Preditiva',
      'Ordem Corretiva',
      'Ordem Planejada',
      'Ordem de restauração'
    ]
      .sort((a, b) => a.localeCompare(b))
      .map(objectify)
  }
];
