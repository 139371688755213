import { useMsal } from '@azure/msal-react';
import { useEffect, useRef, useState } from 'react';
import { BiLogOut } from 'react-icons/bi';
import { FaUserAlt } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { translationBreadCrumbs } from '../../constants/translation/breadCrumbs';

const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  let currentLink = '';
  const crumbs = location.pathname
    .split('/')
    .filter(crumb => crumb !== '')
    .map(crumb => {
      currentLink += '/' + crumb;
      const crumbUpperCased = `${crumb.charAt(0).toUpperCase()}${crumb.slice(
        1
      )}`;

      const crumbFromatted = translationBreadCrumbs[crumbUpperCased];

      if (!crumbFromatted) return null;

      return (
        <Link
          key={currentLink}
          to={currentLink}
          className={`${
            location.pathname === currentLink
              ? 'text-primary'
              : 'text-[#707070]'
          } text-sm font-medium after:content-['_>'] last:after:hidden`}
        >
          {crumbFromatted}
        </Link>
      );
    })
    .filter(crumb => crumb !== null);

  const { instance } = useMsal();

  const handleLogout = async () => {
    try {
      instance.logout();
    } catch (e) {
      toast.error('Erro ao sair');
    }
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let handler = ({ target }: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(target as Node)
      ) {
        setIsOpen(false);
      }
    };

    window.addEventListener('click', handler);
    return () => window.removeEventListener('click', handler);
  }, [dropdownRef]);

  return (
    <header
      className="
        flex justify-between items-center 
        bg-transparent
      "
    >
      <div className="flex gap-1">{crumbs}</div>

      <div className="flex relative" ref={dropdownRef}>
        <button
          onClick={() => setIsOpen(old => !old)}
          className="min-w-[2.5rem] min-h-[2.5rem] rounded-full p-[2px] flex items-center justify-center border-[1.5px] bg-white border-[#DFE0EB]"
        >
          {/* <img
          src="/profilepic.jpg"
          className="w-10 h-10 rounded-full max-w-none"
        /> */}
          <FaUserAlt className="text-[#a6a6a6]" />
        </button>

        <div
          className={`
            absolute 
            bg-white 
            right-0 top-[2.4rem] 
            flex flex-col 
            p-2 
            shadow-light
            before:content-[''] before:absolute before:right-3 before:-top-1 before:w-4 before:h-4 before:bg-white before:rotate-45 before:-z-10
            rounded-lg
            z-50
            transition-all
            ${
              isOpen
                ? 'opacity-100 translate-y-5 visible'
                : 'opacity-0 translate-y-0 invisible'
            }
          `}
        >
          <button
            className="flex items-center gap-6 py-2 px-3 hover:bg-btn-light rounded-md group transition-all"
            onClick={handleLogout}
          >
            <BiLogOut
              className="text-[#a6a6a6] flex-none group-hover:text-[#292929] transition-all"
              size={16}
            />
            <span className="text-sm text-gray group-hover:text-[#292929] transition-all">
              Sair
            </span>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
