import { Column } from 'react-table';
import { tablefy } from '../../utils/tablesUtils';

const items: any = {
  Barreiras: '3328',
  'Profundidade (m)': '3243',
  'Intervalo Superior (m)': '3330',
  'Intervalo Inferior (m)': '3329',
  Zona: '3331',
  Observações: '3332'
};

const bdoSptMecanicalConditions: Column[] = tablefy(
  items,
  undefined,
  ['Profundidade (m)', 'Intervalo Superior (m)', 'Intervalo Inferior (m)'],
  undefined,
  undefined,
  undefined,
  undefined,
  true
);

export default bdoSptMecanicalConditions;
