import { DatepickerCell } from '@/components/atoms/table/DatepickerCell';
import { TextareaCell } from '@/components/atoms/table/TextareaCell';
import { useAccount } from '@azure/msal-react';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';
import { Header } from '../../../components/atoms/table/Header';
import { DateHourCell } from '../../../components/atoms/table/HourCell';

const priority = [
  {
    Header: () => <Header text={'N° OM'} id="N° OM" />,
    accessor: 'ordem',
    Cell: DefaultCell,
    width: '1%'
  },
  {
    Header: () => <Header text={'Nota'} id="Nota" />,
    accessor: 'nota',
    Cell: DefaultCell,
    width: '1%'
  },
  {
    Header: () => <Header text={'Estação'} id="Estação" />,
    accessor: 'estacao',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Equipamento'} id="Equipamento" />,
    accessor: 'equipamento',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Descrição Breve'} id="Descrição Breve" />,
    accessor: 'descricao',
    Cell: DefaultCell,
    width: '20%'
  },
  {
    Header: () => (
      <Header text={'Cenário de Priorização'} id="Cenario de Priorizacao" />
    ),
    accessor: 'cenarios_descricao',
    Cell: DefaultCell,
    width: '20%'
  },
  {
    Header: () => <Header text={'Tipo de Atividade'} id="Tipo de Atividade" />,
    accessor: 'tipo_atividade',
    Cell: DefaultCell
  },
  {
    Header: () => (
      <Header text={'Data de Vencimento'} id="Data de Vencimento" />
    ),
    accessor: 'data_aprovacao_gestor',
    Cell: (e: any) => {
      if (e.row.original.data_aprovacao_gestor != null) {
        return (
          <DateHourCell
            value={e.row.original.data_priorizada_sugerida}
            row={e.row}
            dateFormat="dd/MM/yyyy"
          />
        );
      } else {
        return (
          <DateHourCell
            value={e.row.original.concl_desejada}
            row={e.row}
            dateFormat="dd/MM/yyyy"
          />
        );
      }
    }
  },
  {
    Header: () => <Header text={'Data Priorizada'} id="Data Priorizada" />,
    accessor: 'data_priorizada',
    Cell: ({ row: { id, original }, handleScroll }: any) => {
      const user = useAccount();
      const userCanEdit =
        user?.idTokenClaims?.roles?.includes('Portal.Admin') ||
        user?.idTokenClaims?.roles?.includes('Portal.PiopAdmin');

      return (
        <DatepickerCell
          name={`data.${id}.data_priorizada`}
          keyName={`data.${original.id}.data_priorizada`}
          dateFormat="dd/MM/yyyy"
          value={original.data_priorizada}
          suggestedPrioritizedDate={original.data_priorizada_sugerida}
          disabled={userCanEdit ? false : !!original.data_priorizada}
          handleScroll={handleScroll}
        />
      );
    }
  },
  {
    Header: () => <Header text={'Data de Criação'} id="dataCriacaoNota" />,
    accessor: 'data_criacao_nota',
    Cell: (e: any) => <DateHourCell {...e} dateFormat="dd/MM/yyyy" />,
    width: '12.5%',
    minWidth: 125
  },
  {
    Header: () => <Header text={'Observações'} id="Observacoes" />,
    accessor: 'observacoes',
    Cell: ({ row: { id, original } }: any) => {
      return (
        <TextareaCell
          name={`data.${id}.observacoes`}
          keyName={`data.${original.id}.observacoes`}
          value={original.observacoes ? original.observacoes : ''}
          rows={4}
        />
      );
    }
  }
];

export default priority;
