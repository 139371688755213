import Button from '@/components/atoms/Button';
import { PageWrapper } from '@/components/atoms/PageWrapper';
import { PrintStyle } from '@/components/atoms/PrintStyle';
import { SwitchComponentForm } from '@/components/molecules/SwitchComponentForm';
import bdoSptIntEquipmentCols from '@/constants/tableColumns/bdoSptIntEquipmentCols';
import bdoSptMecanicalConditions from '@/constants/tableColumns/bdoSptMecanicalConditions';
import bdoSptOperationsSummaryCols from '@/constants/tableColumns/bdoSptOperationsSummary';
import {
  NestedStructureItem,
  StructureType,
  SummaryBigCardsStructure
} from '@/constants/view/types/structure.type';
import {
  getSPTFinalInterventionData,
  SPT,
  SPTData
} from '@/services/bdos.services';
import { CustomField } from '@/types/redmine';
import { grid2array } from '@/utils/customFields';
import {
  applyOnNestedValue,
  getNestedValue,
  setNestedValue
} from '@/utils/table';
import { getStatusChangeById, StatusChange } from '@/utils/utils';
import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';
import { useParams as useUrlParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { twMerge } from 'tailwind-merge';
import H1 from '../../../components/atoms/H1';
import Spinner from '../../../components/atoms/Spinner';
import {
  INDICE_OBJETIVO_ATUAL,
  SITOP_OBJETIVO_ATUAL,
  SPT_DESCRICAO_OBJETICOS
} from '@/pages/InterventionReport';

const OBJETIVO_INTEVENCAO_CF_ID = 3198;
const START_DATE_CF_ID = 248;
const GRUPO_EQUIPAMENTO_CF_ID = 3236;

const VALIDATION_STATUS = 145;
const INTERVENTION_STATUS = 144;
const CONCLUDED_STATUS = 146;
const RFI_STATUS = 159;

const PARENT_LITERAL = 'parent';
const OLDEST_LITERAL = 'oldest';
const CONCLUDED_LITERAL = 'concluded';
const TO_ENGINEER_VALIDATION_LITERAL = 'ToEngineerValidation';
const FROM_ON_INTERVENTION_LITERAL = 'FromOnIntervention';
const UPDATED_ON_LITERAL = 'updated_on';
const APROVADOR_LITERAL = 'Aprovador';
const ELABORADOR_LITERAL = 'Elaborador';
const TEMPO_REALIZACAO_LITERAL = 'Realizado';
const OPE_LITERAL = 'ope';
const STB_LITERAL = 'stb';
const DTM_LITERAL = 'dtm';
const TNF_LITERAL = 'tnf';
const SITOP_LITERL = 'sitop';
const LET_DOWN_LITERAL = 'let_down';
const TOTAL_TIME_LITERAL = 'total_time';
const OBJETIVO_AGGREGATED = 'objetivo_agg';
const DURACAO_REALIZACAO_LITERAL = 'duracao_realizacao';

const GRID_SUPERFICIE_KEY = `${CONCLUDED_LITERAL}.3247`;
const GRID_SUBSUPERFICIE_KEY = `${CONCLUDED_LITERAL}.3393`;
const GRID_COLUNA_DE_HASTE_KEY = `${CONCLUDED_LITERAL}.3492`;

const reportHeaderStructure: NestedStructureItem = [
  {
    id: `${TO_ENGINEER_VALIDATION_LITERAL}.date`,
    name: 'Data do Relatório',
    primitiveType: 'datetime'
  },
  {
    id: `${FROM_ON_INTERVENTION_LITERAL}.author`,
    name: 'Fiscal',
    primitiveType: 'string'
  },
  {
    id: `${OLDEST_LITERAL}.${START_DATE_CF_ID}`,
    name: 'Início da Intervenção',
    primitiveType: 'date'
  },
  {
    id: `${CONCLUDED_LITERAL}.${START_DATE_CF_ID}`,
    name: 'Fim da Intervenção',
    primitiveType: 'date'
  },
  {
    id: `${PARENT_LITERAL}.${UPDATED_ON_LITERAL}`,
    name: 'Última Atualização',
    primitiveType: 'datetimeiso'
  }
];

const footerStructure: NestedStructureItem = [
  {
    id: `${ELABORADOR_LITERAL}.author`,
    name: 'Elaborador',
    primitiveType: 'string'
  },
  {
    id: `${APROVADOR_LITERAL}.author`,
    name: 'Aprovador',
    primitiveType: 'string'
  },
  {
    id: `${CONCLUDED_LITERAL}.3197`,
    name: 'Encarregado',
    primitiveType: 'string'
  }
];

const summaryBigCardsStructure: SummaryBigCardsStructure = [
  {
    name: 'Duração (dias)',
    priority: 'high',
    structure: [
      {
        id: `${TEMPO_REALIZACAO_LITERAL}`,
        name: 'Realizada',
        primitiveType: 'shortNumber'
      },
      {
        id: `${DURACAO_REALIZACAO_LITERAL}`,
        name: 'Estimada',
        primitiveType: 'shortNumber'
      }
    ]
  },
  {
    name: 'Tempo (horas)',
    priority: 'high',
    structure: [
      {
        id: `${OPE_LITERAL}`,
        name: 'OPE',
        primitiveType: 'shortNumber'
      },
      {
        id: `${STB_LITERAL}`,
        name: 'STB',
        primitiveType: 'shortNumber'
      },
      {
        id: `${DTM_LITERAL}`,
        name: 'DTM',
        primitiveType: 'shortNumber'
      },
      {
        id: `${TNF_LITERAL}`,
        name: 'TNF',
        primitiveType: 'shortNumber'
      }
    ]
  },
  {
    name: 'Custo (R$ MM)',
    priority: 'high',
    structure: [
      {
        id: `${SITOP_LITERL}.3455`,
        name: 'Realizado',
        primitiveType: 'number'
      },
      {
        id: `${PARENT_LITERAL}.3376`,
        name: 'Estimado',
        primitiveType: 'number'
      }
    ]
  }
];

const simpleCardsStructure: SummaryBigCardsStructure = [
  {
    name: 'Let Down (m)',
    structure: [
      {
        id: `${LET_DOWN_LITERAL}`,
        primitiveType: 'shortNumber'
      }
    ],
    priority: 'medium'
  },
  {
    name: 'Fundo do Poço (m)',
    structure: [
      {
        id: `${PARENT_LITERAL}.3493`,

        primitiveType: 'shortNumber'
      }
    ],
    priority: 'medium'
  },
  {
    name: 'Tempo Total (horas)',
    structure: [
      {
        id: `${TOTAL_TIME_LITERAL}`,

        primitiveType: 'shortNumber'
      }
    ],

    priority: 'medium'
  }
];

const bdoSptInterventionCfInfo: StructureType = [
  {
    type: 'report-header',
    id: 'Header',
    structure: reportHeaderStructure
  },
  {
    type: 'summary-cards',
    id: 'SummaryBigCards',
    structure: summaryBigCardsStructure
  },
  {
    type: 'label',
    id: 'Informações Gerais',
    name: 'Informações Gerais',
    noBorder: true
  },
  {
    type: 'summary-cards',
    id: 'SimpleCards',
    structure: simpleCardsStructure
  },
  {
    type: 'label',
    id: 'Objetivo da Intervenção',
    name: 'Objetivo da Intervenção',
    noBorder: true
  },
  {
    type: 'text',
    id: `${OBJETIVO_AGGREGATED}`,
    name: 'Objetivo da Intervenção'
  },
  {
    type: 'label',
    id: 'Equipamentos',
    name: 'Equipamentos',
    noBorder: true
  },
  {
    id: GRID_SUPERFICIE_KEY,
    type: 'grid',
    columns: bdoSptIntEquipmentCols(true),
    name: 'Superfície',
    tableContainerClassName: 'rounded-lg bg-white px-4 py-3 gap-2',
    containerClassName: 'break-inside-auto',
    headerClassName: 'bg-[#193CB9]'
  },
  {
    id: GRID_SUBSUPERFICIE_KEY,
    type: 'grid',
    columns: bdoSptIntEquipmentCols(true),
    name: 'Subsuperfície',
    tableContainerClassName: 'rounded-lg bg-white px-4 py-3 gap-2',
    containerClassName: 'break-inside-auto',
    headerClassName: 'bg-[#193CB9]'
  },
  {
    id: GRID_COLUNA_DE_HASTE_KEY,
    type: 'grid',
    columns: bdoSptIntEquipmentCols(true),
    name: 'Coluna de haste',
    tableContainerClassName: 'rounded-lg bg-white px-4 py-3 gap-2',
    containerClassName: 'break-inside-auto',
    headerClassName: 'bg-[#193CB9]'
  },
  {
    type: 'label',
    id: 'Condições Mecânicas',
    name: 'Condições Mecânicas',
    noBorder: true
  },
  {
    id: `${PARENT_LITERAL}.3333`,
    type: 'grid',
    columns: bdoSptMecanicalConditions,
    tableContainerClassName: 'rounded-lg bg-white px-4 py-3 gap-2',
    containerClassName: 'break-inside-auto',
    headerClassName: 'bg-[#193CB9]'
  },
  {
    type: 'label',
    id: 'Resumo das Operações',
    name: 'Resumo das Operações',
    noBorder: true
  },
  {
    id: `${PARENT_LITERAL}.3339`,
    type: 'raw-table',
    columns: bdoSptOperationsSummaryCols,
    tableClassName: 'break-inside-auto',
    rowClassName: 'mb-2 rounded bg-white px-4 py-3'
  },
  {
    id: 'Footer',
    type: 'footer',
    structure: footerStructure
  }
];

export const FinalSptInterventionReport = () => {
  const { id } = useUrlParams();

  const { data, isFetching } = useQuery(
    ['InterventionFinalReport', id],
    () => getSPTFinalInterventionData(id || ''),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!id
    }
  );

  const { data: statusChange, isFetching: isFetchingStatus } = useQuery(
    ['RFIStatusChange', id],
    () => getStatusChangeById(id || ''),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!id
    }
  );

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const title = 'Relatório Final de Intervenção';
  const titleSubject = getInterventionCode(data?.data?.parent);
  const statusId = getStatusId(data?.data?.parent);
  const switchComponents = parseDataToSwitchComponentForm(data, statusChange);

  return (
    <>
      <PageWrapper>
        <div className="flex justify-end gap-2 absolute right-12 -top-10">
          <a
            className="w-fit"
            href={`/home/intervention-panel/${id}`}
            target="_self"
          >
            <Button className="w-full" title="BDO" type="button" />
          </a>
        </div>

        {isFetching ? (
          <Spinner size={36} classNameWrapper="my-4" />
        ) : (
          <div ref={componentRef} className="bdmf-print bg-background pb-6">
            <PrintStyle multiplier={1.85} />
            <img
              src="/origem.png"
              alt="Logo Origem"
              className="w-[203px] resize-none max-w-none -ml-5 only-print"
            />

            <H1>
              <div className="flex gap-3 items-center">
                {`${title} | ${titleSubject}`}
                {statusIdToDisplayInfo[statusId] && (
                  <div
                    className={twMerge(
                      'rounded-full py-1 font-medium text-base px-3',
                      `${statusIdToDisplayInfo[statusId]['color']}`
                    )}
                  >
                    {statusIdToDisplayInfo[statusId]['standardName']}
                  </div>
                )}
              </div>
              <button
                onClick={handlePrint}
                className="text-base font-medium px-2 py-1 rounded border no-print"
              >
                Imprimir
              </button>
            </H1>

            {data ? <SwitchComponentForm {...switchComponents} /> : null}
          </div>
        )}
      </PageWrapper>
    </>
  );
};

const parseDataToSwitchComponentForm = (
  spt: SPT | undefined,
  statusChange?: StatusChange[] | null
) => {
  const data: { [id: string]: any } = {};

  data[PARENT_LITERAL] = insertCustomFields(
    spt?.data?.parent?.custom_fields || []
  );
  data[PARENT_LITERAL][UPDATED_ON_LITERAL] =
    spt?.data?.parent?.issue.updated_on;
  data[OLDEST_LITERAL] = insertCustomFields(
    spt?.data?.oldest?.custom_fields || []
  );
  data[CONCLUDED_LITERAL] = insertCustomFields(
    spt?.data?.concluded?.custom_fields || []
  );
  data[SITOP_LITERL] = insertCustomFields(
    spt?.data?.sitop?.custom_fields || []
  );
  data[OBJETIVO_AGGREGATED] = getAggregatedObjective(data);
  data[DURACAO_REALIZACAO_LITERAL] = getActualDuration(data);

  data[OPE_LITERAL] = spt?.data.ope;
  data[STB_LITERAL] = spt?.data.stb;
  data[DTM_LITERAL] = spt?.data.dtm;
  data[TNF_LITERAL] = spt?.data.tnf;

  data[TOTAL_TIME_LITERAL] =
    +(spt?.data.ope || 0) +
    +(spt?.data.stb || 0) +
    +(spt?.data.dtm || 0) +
    +(spt?.data.tnf || 0);

  applyOnNestedValue(data, `${SITOP_LITERL}.3455`, convertToMM);
  applyOnNestedValue(data, `${PARENT_LITERAL}.3376`, convertToMM);

  data[LET_DOWN_LITERAL] = grid2array(
    getNestedValue(data, GRID_SUBSUPERFICIE_KEY)?.replace(/=>/g, ':') || '{}'
  )[0]?.[3243];

  const realizado =
    new Date(
      getNestedValue(data, `${CONCLUDED_LITERAL}.${START_DATE_CF_ID}`)
    )?.getTime() -
    new Date(
      getNestedValue(data, `${OLDEST_LITERAL}.${START_DATE_CF_ID}`)
    )?.getTime();
  const diffInDays = Math.floor(realizado / (1000 * 60 * 60 * 24));

  setNestedValue(data, TEMPO_REALIZACAO_LITERAL, diffInDays);

  applyOnNestedValue(data, GRID_SUPERFICIE_KEY, filterGridOnFinalComposition);

  applyOnNestedValue(
    data,
    GRID_SUBSUPERFICIE_KEY,
    filterGridOnFinalComposition
  );

  applyOnNestedValue(
    data,
    GRID_COLUNA_DE_HASTE_KEY,
    filterGridOnFinalComposition
  );

  if (statusChange) {
    data[TO_ENGINEER_VALIDATION_LITERAL] =
      filterToEngineerValidation(statusChange);
    data[FROM_ON_INTERVENTION_LITERAL] = filterFromOnIntervention(statusChange);
    data[APROVADOR_LITERAL] = filterAprovador(statusChange);
    data[ELABORADOR_LITERAL] = filterElaborador(statusChange);
  }

  return {
    structure: bdoSptInterventionCfInfo,
    data,
    noGridBorder: true,
    isCol: true,
    blueLabel: true
  };
};

const getAggregatedObjective = (data: { [id: string]: any }) => {
  const grid_objetivo = grid2array(
    getNestedValue(
      data,
      `${PARENT_LITERAL}.${SPT_DESCRICAO_OBJETICOS}`
    )?.replace(/=>/g, ':') || '{}'
  );

  return grid_objetivo
    ?.map(obj => obj[3801] + ' - ' + obj[OBJETIVO_INTEVENCAO_CF_ID])
    .join('\n');
};

const getActualDuration = (data: { [id: string]: any }) => {
  const extractObjectiveNumber = (str?: string) => str?.split('-')[0]?.trim();

  const actualObjectiveTag = extractObjectiveNumber(
    getNestedValue(data, `${SITOP_LITERL}.${SITOP_OBJETIVO_ATUAL}`)
  );

  const grid_objetivo = grid2array(
    getNestedValue(
      data,
      `${PARENT_LITERAL}.${SPT_DESCRICAO_OBJETICOS}`
    )?.replace(/=>/g, ':') || '{}'
  );

  const actualObjetctiveDetails = grid_objetivo.find(
    objective => objective[INDICE_OBJETIVO_ATUAL] === actualObjectiveTag
  );

  return actualObjetctiveDetails?.[3312];
};

const insertCustomFields = (customFields: CustomField[]) => {
  const data: { [id: string]: any } = {};
  customFields.forEach(cf => {
    if (cf.value) {
      data[cf.id.toString()] = cf.value;
    }
  });

  return data;
};

const filterGridOnFinalComposition = (gridJson: string) => {
  const grid = grid2array(gridJson?.replace(/=>/g, ':') || '{}');
  return JSON.stringify(
    grid.filter(kv => kv[GRUPO_EQUIPAMENTO_CF_ID] === 'Composição final')
  );
};

const filterToEngineerValidation = (statusChange: StatusChange[]) =>
  filterStatusChange(statusChange, undefined, VALIDATION_STATUS);

const filterFromOnIntervention = (statusChange: StatusChange[]) =>
  filterStatusChange(statusChange, INTERVENTION_STATUS, undefined);

const filterElaborador = (statusChange: StatusChange[]) =>
  filterStatusChange(statusChange, INTERVENTION_STATUS, VALIDATION_STATUS);

const filterAprovador = (statusChange: StatusChange[]) =>
  filterStatusChange(statusChange, VALIDATION_STATUS, RFI_STATUS);

const filterStatusChange = (
  statusChange: StatusChange[],
  old_status?: number,
  new_status?: number
) => {
  const filteredData = statusChange
    .filter(
      sc =>
        (old_status ? sc.status.old_value === old_status : true) &&
        (new_status ? sc.status.new_value === new_status : true)
    )
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  if (filteredData.length > 0) {
    return filteredData[0];
  }

  return undefined;
};

const getInterventionCode = (parent: SPTData | undefined) => {
  const subject = parent?.issue?.subject || '';
  const words = subject.split('|');
  if (words.length > 1) {
    return words[1].trim();
  }
  return '';
};

const getStatusId = (parent: SPTData | undefined) => {
  return parent?.issue?.status_id || 0;
};

export const convertToMM = (value: any) => {
  if (value === null || value === undefined) {
    return undefined;
  }

  const numericValue = parseFloat(value);
  if (isNaN(numericValue)) {
    return undefined;
  }
  return numericValue / 1000000;
};

const statusIdToDisplayInfo: Record<number, any> = {
  142: {
    standardName: 'Não iniciado', // Cadastro da Intervenção
    color: 'bg-gray-lighter text-gray-dark'
  },
  144: {
    standardName: 'Em Preenchimento', // Execução da Intervenção
    color: 'bg-[#D7DFFF]/40 text-[#193CB9]'
  },
  145: {
    standardName: 'Validação Engenharia', // Validação da Intervenção
    color: 'bg-[#FFE8D7]/40 text-[#EC7100]'
  },
  146: {
    standardName: 'Aprovado', // Intervenção Finalizada
    color: 'bg-[#D0FFE1] text-[#5F9573]'
  }
};
