import { msalInstance } from '@/main';
import { SilentRequest } from '@azure/msal-browser';

export function getAccessTokenRequest(): SilentRequest {
  const accessTokenRequest: SilentRequest = {
    scopes: [`api://${import.meta.env.VITE_MSAL_CLIENT_ID}/Portal.User`],
    account: msalInstance.getAllAccounts()[0]
  };

  return accessTokenRequest;
}

export const getUserToken = async () => {
  return (await msalInstance.acquireTokenSilent(getAccessTokenRequest()))
    .accessToken;
};

export const getUserTokenToApiRequest = async (config: any) => {
  const userToken = await getUserToken();
  return {
    ...config,
    headers: {
      Authorization: `Bearer ${userToken}`
    }
  };
};
