import HomeCards from '../../components/molecules/HomeCards';

const ProductionMonitoring: React.FC = () => {
  const cards = [
    {
      title: 'Ficha de Poço',
      description:
        'Registro das informações diárias de operações do Polo Alagoas e Tucano Sul',
      img: '/home2.png',
      redirect: [{ to: '/home/oilwell-info', title: 'Ficha do Poço' }]
    },
    {
      title: 'Teste de Poço',
      description:
        'Dados cadastrais e históricos dos poços de Alagoas e Tucano Sul',
      img: '/home1.png',
      redirect: [{ to: '/home/tests-workflow', title: 'Workflow de testes' }]
    },
    {
      title: 'BDO',
      description:
        'Registro das informações diárias de operações do Polo Alagoas e Tucano Sul',
      img: '/home3.png',
      redirect: [{ to: '/home/bdo', title: 'Histórico de BDOs' }]
    },
    {
      title: 'BDMF',
      description:
        'Boletim diário de medição fiscal com resultados oficiais de produção para reporte à ANP',
      img: '/home4.png',
      redirect: [{ to: '/home/bdmf', title: 'BDMF' }]
    },
    {
      title: 'Permissões de Trabalho',
      description:
        'Acompanhamento das PTs em processo de emissão e consulta do histórico',
      img: '/well.jpeg',
      redirect: [{ to: '/home/work-permit', title: 'PT' }]
    },
    {
      title: 'Planejamento Integrado de Operações',
      description:
        'Acompanhamento e priorização das atividades integradas de Operações',
      img: '/piop.png',
      redirect: [{ to: '/home/piop', title: 'PIOP' }]
    }
    // {
    //   title: "Alertas de Produção",
    //   description:
    //     "Alertas de vazão gerados a partir dos testes de poço ANP e Operacionais validados",
    //   img: "/redalert.webp",
    //   redirect: [{ to: "/home/piop", title: "PIOP" }],
    // },
  ];

  return (
    <div className="w-full h-[calc(100%-45.6px)] flex flex-col overflow-y-auto scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full">
      <div className="w-fit grid grid-cols-2 grid-rows-2 gap-4 m-auto">
        {cards.map(card => (
          <HomeCards
            key={card.title}
            title={card.title}
            description={card.description}
            img={card.img}
            redirect={card?.redirect}
            className="max-w-[550px]"
            disabled={!card?.redirect}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductionMonitoring;
