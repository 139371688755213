import { DateValueType } from 'react-tailwindcss-datepicker';
import { atom } from 'recoil';

export interface PiopFilters {
  timeRange: DateValueType;
  timeRangeDataPriorizada: DateValueType;
  timeRangeDataCriacao: DateValueType;
  estacao: string[];
  localInstalacao: string[];
  equipamentos: string[];
  ordem: string;
  nota: string;
  statusOrdem: string[];
  descricaoBreve: string;
  cenarioDePriorizacao: string[];
  tipoDeAtividade: string[];
}

export const PiopReportFiltersAtom = atom<PiopFilters>({
  key: 'PiopReportFilters',
  default: {
    timeRange: {
      startDate: null,
      endDate: null
    },
    timeRangeDataPriorizada: {
      startDate: null,
      endDate: null
    },
    timeRangeDataCriacao: {
      startDate: null,
      endDate: null
    },
    estacao: [],
    localInstalacao: [],
    equipamentos: [],
    ordem: '',
    nota: '',
    statusOrdem: ['Não Concluída'],
    descricaoBreve: '',
    cenarioDePriorizacao: [],
    tipoDeAtividade: []
  }
});
