import { atom } from 'recoil';

export interface PiopFilters {
  station: string[];
  discipline: string[];
  status: string[];
  priority: string[];
  equipment: string[];
  timeRange: {
    endDate?: Date | string;
    startDate?: Date | string;
  };
  scheduledDate: {
    endDate?: Date | string;
    startDate?: Date | string;
  };
  order: string;
  operation: string;
}

export const PiopFiltersAtom = atom<PiopFilters>({
  key: 'PiopFilters',
  default: {
    timeRange: {
      endDate: undefined,
      startDate: undefined
    },
    scheduledDate: {
      endDate: undefined,
      startDate: undefined
    },
    order: '',
    equipment: [],
    operation: '',
    station: [],
    discipline: [],
    status: [],
    priority: []
  }
});
