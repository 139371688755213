import React, { ReactNode, useEffect, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';

import { twMerge } from 'tailwind-merge';

interface AccordionProps extends React.HTMLAttributes<HTMLDivElement> {
  titleComponent: (isOpen: boolean) => JSX.Element;
  children: ReactNode;
  className?: string;
  buttonClassName?: string;
  childrenClassName?: string;
  forceClose?: boolean;
  isPrinting?: boolean;
}

const TogglePanel: React.FC<AccordionProps> = ({
  titleComponent,
  children,
  buttonClassName,
  childrenClassName,
  forceClose = false,
  isPrinting,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = () => {
    setIsOpen(old => !old);
  };

  useEffect(() => {
    if (forceClose) {
      setIsOpen(false);
    }
  }, [forceClose]);

  const TitleComponent = titleComponent;
  return (
    <div {...rest}>
      <section
        className={twMerge(
          `w-full 
          min-h-[40px]
          px-3 py-2
          flex flex-col items-center
          hover:bg-[#f5f5f5]
          rounded`,
          buttonClassName ?? ''
        )}
      >
        <button
          className="flex w-full items-center text-primary font-bold"
          onClick={handleChange}
        >
          {TitleComponent(isOpen)}
          {!forceClose && (
            <FaChevronDown
              className={`fill-primary ml-auto transition-all ${
                isOpen && 'rotate-180'
              }`}
            />
          )}
        </button>

        <section
          className={twMerge(
            `${
              (isOpen && !forceClose) || isPrinting
                ? 'max-h-96 opacity-100 visible w-full'
                : '!max-h-0 opacity-0 invisible'
            } overflow-hidden transition-all duration-500 ease-in-out`,
            childrenClassName
          )}
        >
          {children}
        </section>
      </section>
    </div>
  );
};

export default TogglePanel;
