import { useQuery } from '@tanstack/react-query';
import { addDays, addHours, format, subDays } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { twMerge } from 'tailwind-merge';
import Button from '../../components/atoms/Button';
import EmptyMessage from '../../components/atoms/EmptyMessage';
import H1 from '../../components/atoms/H1';
import { PageWrapper } from '../../components/atoms/PageWrapper';
import SelectInput from '../../components/atoms/Select';
import Spinner from '../../components/atoms/Spinner';
import PageNavigation from '../../components/molecules/PageNavigation';
import Table from '../../components/organisms/Table';
import { piopStatus } from '../../constants/enums/status';
import { PiopFilters } from '../../constants/filters/PiopFilters';
import { PiopFilters as PiopFiltersInterface } from '../../state/piop.filters';
import weekly from '../../constants/tableColumns/piop/weekly';
import {
  ExtendedRedmineIssuesResponse,
  getPiopRelations,
  getPiopInfo,
  getAllPiopRelations
} from '../../services/bdos.services';
import { PiopFiltersAtom } from '../../state/piop.filters';
import { getFieldByArray } from '../../utils/tablesUtils';
import TabsItem from '../../components/atoms/TabsItem';

type PiopGetter = (
  filters: {
    station: string[];
    discipline: string[];
    status: string[];
    priority: string[];
    equipment: string[];
    timeRange: {
      startDate?: Date | string;
      endDate?: Date | string;
    };
    scheduledDate: {
      startDate?: Date | string;
      endDate?: Date | string;
    };
    onlyWithDep?: boolean;
    operation?: string;
    order?: string;
  },
  status?: string | number | null
) => Promise<ExtendedRedmineIssuesResponse>;

export const Summary = () => {
  const [params, setParam] = useSearchParams();

  type MenuType = 1 | 2 | 3;

  const [menu, setMenu] = useState<MenuType>(
    (Number(params.get('plan')) || 1) as MenuType
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [filtersState, setFiltersState] = useRecoilState(PiopFiltersAtom);
  const resetFilters = useResetRecoilState(PiopFiltersAtom);
  const [filteredData, setFilteredData] = useState({
    ...filtersState,
    scheduledDate: { startDate: new Date(), endDate: new Date() }
  } as PiopFiltersInterface);
  const [issuesMax, setIssuesMax] = useState<any>();
  const perPage = 30;

  const handleFilter = (isClearing?: boolean) => {
    if (!isClearing) {
      setFilteredData(filtersState);
    } else {
      resetFilters();
      setFilteredData({
        station: [],
        discipline: [],
        status: [],
        priority: [],
        operation: '',
        equipment: [],
        order: '',
        timeRange: {
          endDate: undefined,
          startDate: undefined
        },
        scheduledDate: {
          endDate: new Date(),
          startDate: new Date()
        }
      });
    }
  };

  useEffect(() => {
    setIssuesMax(undefined);
  }, [menu, filteredData]);

  const getData = async () => {
    return await filterPiopData((filters, status) =>
      getPiopRelations(perPage, menu, currentPage, filters, undefined, status)
    );
  };

  const getAllData = async () => {
    if (issuesMax) return handlePrint();
    if (!issues) throw new Error('No data');
    const loadingToast = toast.loading('Aguarde enquanto carregamos os dados');
    try {
      const response = await filterPiopData((filters, status) =>
        getAllPiopRelations(
          menu,
          filters,
          issues.meta?.count,
          undefined,
          status
        )
      );

      setIssuesMax(response);

      setTimeout(handlePrint, 1000);
    } catch (e) {
      toast.error('Erro ao buscar dados');
    } finally {
      toast.dismiss(loadingToast);
    }
  };

  const filterPiopData = async (getter: PiopGetter) => {
    const data = await getter(
      filteredData,
      menu == 1
        ? piopStatus['Aguardando Simultaneidade/Gerenciamento de Recurso']
        : menu == 2
          ? piopStatus['Simultaneidade Aprovada']
          : null
    );

    const relations = data['relations'];

    const relationsHash = relations
      ? relations.reduce((acc: any, it: any) => {
          if (it.project_id_to !== 8) return acc;
          acc[it.from_id] = it;
          return acc;
        }, {})
      : {};

    const response = {
      ...data.issues,
      meta: data.meta,
      data: data?.issues?.map((it: any) => ({
        issue_id: it.issue.id,
        title: it.issue.subject,
        order: getFieldByArray(it.custom_fields, 2320),
        discipline: getFieldByArray(it.custom_fields, 2339),
        equipment: getFieldByArray(it.custom_fields, 2336),
        description: getFieldByArray(it.custom_fields, 2324),
        descriptionOp: getFieldByArray(it.custom_fields, 2341),
        startScheduled: getFieldByArray(it.custom_fields, 2497)
          ? format(
              addHours(getFieldByArray(it.custom_fields, 2497), 3),
              'dd/MM/yyyy'
            )
          : null,
        wellAffected: getFieldByArray(it.custom_fields, 2423),
        priority: getFieldByArray(it.custom_fields, 2343),
        station: getFieldByArray(it.custom_fields, 2338),
        dataBaseEnd: getFieldByArray(it.custom_fields, 2331)
          ? format(
              addHours(getFieldByArray(it.custom_fields, 2331), 3),
              'dd/MM/yyyy'
            )
          : null,
        date: getFieldByArray(it.custom_fields, 2510),
        relations: relationsHash[it.issue.id]
      }))
    };

    return response;
  };

  const filterButtons = {
    1: {
      label: 'Plano Semanal',
      onClick: () => {
        setParam({ plan: '1' });
        setMenu(1);
      },
      value: addDays(new Date(), 7)
    },
    2: {
      label: 'Simultaneidade',
      onClick: () => {
        setParam({ plan: '2' });
        setMenu(2);
      },
      value: addDays(new Date(), 1)
    },
    3: {
      label: 'Plano de 90 dias',
      onClick: () => {
        setParam({ plan: '3' });
        setMenu(3);
      },
      value: addDays(new Date(), 90)
    }
  };

  const {
    data: issues,
    isLoading,
    isFetching
  } = useQuery({
    queryFn: async () => getData(),
    queryKey: ['Piop-data', menu, filteredData, currentPage],
    refetchOnWindowFocus: false
  });

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const multiplier = 2;

  const styles = `
    @media only print {
      @page { 
        size: ${210 * multiplier}mm ${297 * multiplier}mm;
      }
    }
  `;

  const SelectOption = (key: string) => {
    return (selectedOptions: any) => {
      setFiltersState((prevFilters: any) => {
        return {
          ...prevFilters,
          [key]: selectedOptions.map((option: any) => option.value)
        };
      });
    };
  };

  return (
    <>
      <PageWrapper>
        <style>{styles}</style>
        <H1>
          {filterButtons?.[menu].label}
          <button
            className="text-base font-medium px-2 py-1 rounded border no-print disabled:opacity-30"
            onClick={getAllData}
            disabled={!(!!issues && !isFetching)}
          >
            Imprimir
          </button>
        </H1>

        <div className="flex flex-col w-full px-4 py-3 bg-white rounded-lg z-[11]">
          <H1 className="text-slate-700">Filtros</H1>
          <div className="flex w-full gap-2">
            <div className="flex flex-col flex-1">
              <h3 className="text-sm font-bold text-slate-600">
                Início Planejado
              </h3>
              <Datepicker
                value={
                  (filtersState.scheduledDate
                    ? filtersState.scheduledDate
                    : {
                        startDate: new Date(),
                        endDate: new Date()
                      }) as DateValueType
                }
                useRange={false}
                primaryColor={'sky'}
                onChange={(val: any) => {
                  setFiltersState((prevFilters: any) => ({
                    ...prevFilters,
                    scheduledDate: val ? val : new Date()
                  }));
                }}
                i18n="pt-br"
                placeholder="Selecione uma data"
                inputClassName={
                  'relative transition-all duration-300 h-[36px] pl-2 pr-10 w-full border-[1px] border-[#dddd] dark:border-slate-600 rounded-[8px] tracking-wide font-light text-sm text-slate-800 focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-sky-500 stroke-white focus:ring-sky-500/20'
                }
                classNames={{
                  toggleButton() {
                    return 'absolute right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed stroke-white svg-white';
                  }
                }}
              />
            </div>

            <div className="flex flex-col flex-1">
              <h3 className="text-sm font-bold text-slate-600">Ordem</h3>

              <input
                className="h-9 w-full border border-px border-[#dddd] rounded-lg px-3 bg-transparent text-slate-700 font-medium placeholder-gray placeholder:font-thin"
                value={filtersState.order}
                onChange={e => {
                  setFiltersState((old: any) => ({
                    ...old,
                    order: e.target.value
                  }));
                }}
                placeholder="Ordem"
              />
            </div>

            {PiopFilters.filter(it => it.key !== 'timeRange').map(filter => (
              <div
                key={filter.key}
                className="flex flex-col min-w-[160px] w-[250px] flex-1"
              >
                <h3 className="text-sm font-bold text-slate-600">
                  {filter.name}
                </h3>

                <SelectInput
                  onChange={SelectOption(filter.key)}
                  options={filter.values}
                  value={PiopFilters.find(
                    (it: any) => it.key == filter.key
                  )?.values.filter((it: any) =>
                    (filtersState as any)?.[filter.key]?.includes?.(it.value)
                  )}
                  secondary
                />
              </div>
            ))}
          </div>

          <div className="flex gap-2 justify-end w-full mt-4">
            <Button
              title="Limpar"
              onClick={() => handleFilter(true)}
              className="border-primary text-primary border bg-white hover:bg-white w-40 hover:brightness-95"
            />

            <Button
              title="Aplicar"
              onClick={() => handleFilter(false)}
              className="w-40"
            />
          </div>
        </div>

        <div className="bg-white rounded-[10px] py-4 px-6 h-full mt-4">
          <h1 className="text-gray font-bold text-lg mb-4 w-full flex justify-between">
            <div className="flex gap-2 whitespace-nowrap items-center justify-center">
              Detalhamento OM - SAP
              {isFetching ? <Spinner size={18} /> : null}
            </div>
            <div className="flex gap-2">
              {Object.values(filterButtons).map((it, idx) => (
                <TabsItem {...it} menu={menu} idx={idx + 1} key={it.label} />
              ))}
            </div>
          </h1>

          <div className="h-full max-h-[calc(100vh-350px)] pb-6 overflow-y-scroll scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full">
            {isLoading ? (
              <Spinner size={36} />
            ) : !issues?.data?.length ? (
              <EmptyMessage message="Nenhum dado foi encontrado" />
            ) : (
              <Table
                key="flowRate"
                columns={weekly}
                headerClassName="sticky top-0 z-10 bg-white"
                data={issues.data}
              />
            )}
          </div>

          {!!issues && (
            <PageNavigation
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalItems={issues?.meta?.count}
              className="mt-3"
              totalPages={Math.ceil(issues?.meta?.count / perPage)}
              perPage={perPage}
            />
          )}
        </div>
      </PageWrapper>

      <div className="only-print hidden">
        <div
          className="bg-white rounded-[10px] py-4 px-6 h-full mt-4"
          ref={componentRef}
        >
          <img
            src="/origem.png"
            alt="Logo Origem"
            className="w-[203px] resize-none max-w-none -ml-5 only-print"
          />

          <div className="flex flex-col w-full py-3 bg-white rounded-lg z-[11]">
            <H1 className="text-slate-700">
              Filtros - {format(new Date(), 'dd/MM/yyyy')}
            </H1>
            <div className="flex w-full gap-2">
              <div className="flex flex-col flex-1">
                <h3 className="text-sm font-bold text-slate-600">
                  Início Planejado
                </h3>
                <Datepicker
                  value={
                    (filtersState.scheduledDate
                      ? filtersState.scheduledDate
                      : {
                          startDate: new Date(),
                          endDate: new Date()
                        }) as DateValueType
                  }
                  useRange={false}
                  primaryColor={'sky'}
                  onChange={(val: any) => {
                    setFiltersState((prevFilters: any) => ({
                      ...prevFilters,
                      scheduledDate: val
                    }));
                  }}
                  i18n="pt-br"
                  placeholder="Selecione uma data"
                  inputClassName={
                    'relative transition-all duration-300 h-[36px] pl-2 pr-10 w-full border-[1px] border-[#dddd] dark:border-slate-600 rounded-[10px] tracking-wide font-light text-sm text-slate-800 focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-sky-500 stroke-white focus:ring-sky-500/20'
                  }
                  classNames={{
                    toggleButton() {
                      return 'absolute right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed stroke-white svg-white';
                    }
                  }}
                />
              </div>

              <div className="flex flex-col flex-1">
                <h3 className="text-sm font-bold text-slate-600">Ordem</h3>

                <input
                  className="h-9 w-full border border-px border-[#dddd] rounded-lg px-3 bg-transparent text-slate-700 font-medium placeholder-gray placeholder:font-thin"
                  value={filtersState.order}
                  onChange={e => {
                    setFiltersState((old: any) => ({
                      ...old,
                      order: e.target.value
                    }));
                  }}
                  placeholder="Ordem"
                />
              </div>

              {PiopFilters.filter(it => it.key !== 'timeRange').map(filter => (
                <div
                  key={filter.key}
                  className="flex flex-col min-w-[160px] w-[250px] flex-1"
                >
                  <h3 className="text-sm font-bold text-slate-600">
                    {filter.name}
                  </h3>

                  <SelectInput
                    onChange={SelectOption(filter.key)}
                    options={filter.values}
                    value={PiopFilters.find(
                      (it: any) => it.key == filter.key
                    )?.values.filter((it: any) =>
                      (filtersState as any)?.[filter.key]?.includes?.(it.value)
                    )}
                    secondary
                  />
                </div>
              ))}
            </div>
          </div>

          <h1 className="text-gray font-bold text-lg mb-4 w-full flex justify-between">
            Detalhamento OM - SAP
            <div className="flex gap-2">
              {Object.values(filterButtons).map((it, idx) => (
                <button
                  key={it.label}
                  onClick={it.onClick}
                  className={twMerge(
                    'font-normal bg-[#f5f5f5] text-slate-700 text-sm px-3 py-2 rounded hover:bg-[#ddd] transition-colors',
                    idx + 1 == menu
                      ? 'bg-[#dddddd86] border border-slate-400'
                      : ''
                  )}
                >
                  {it.label}
                </button>
              ))}
            </div>
          </h1>

          <div className="h-full pb-6 overflow-y-scroll scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full">
            {!issuesMax?.data?.length ? (
              <EmptyMessage message="Nenhum dado foi encontrado" />
            ) : (
              <Table key="flowRate" columns={weekly} data={issuesMax?.data} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
