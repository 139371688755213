const translationBreadCrumbs: { [key: string]: string } = {
  Home: 'Página Inicial',
  'Tests-workflow': 'Workflow de Testes',
  'Tests-history': 'Ficha do Poço',
  Calendar: 'Calendário',
  Oilwells: 'Poços',
  Create: 'Novo',
  Update: 'Atualizar',
  Oilfields: 'Campos',
  Satellites: 'Satélites',
  'Gas-meter': 'Medidor de Gás',
  Tank: 'Tanque',
  Chroma: 'Cromatografia GL',
  'Pi-tags': 'Tags PI',
  'Bdo-reports': 'Informe Produção',
  Summary: 'Resumo',
  History: 'Histórico',
  Pressures: 'Pressões',
  'Flow-rate': 'Injeção',
  'Oilwell-info': 'Ficha do Poço',
  Tests: 'Testes',
  'Bdo-history': 'Calendário BDO',
  'Bdo-pts-history': 'Calendário BDO - Tucano Sul',
  'Bdo-view': 'BDO Campo',
  'Production-view': 'Cálculo da Produção',
  'Water-view': 'Transbordo dos Tanques de Água',
  'Follow-up': 'Teste de Acompanhamento',
  Bdo: 'Boletim Diário de Operações',
  'Bdo-ep': 'Estação de Produção',
  Slickline: 'Slickline',
  'Bdo-ec': 'Estação de Compressão',
  Upgn: 'UPGN',
  'Opening-closing': 'Abertura e Fechamento',
  Equips: 'Atividades Operacionais',
  'Rip-control': 'Formulários INP',
  Bdmf: 'BDMF',
  Reports: 'Relatório',
  Alerts: 'Alertas',
  Drilling: 'Boletim de Perfuração',
  Zones: 'Zonas',
  'Oilwell-zone-allocation': 'Alocação Poço-Zona',
  'Production-analysis-2h': 'Análise de Produção 2h',
  'Production-analysis-pts-2h': 'Análise de Produção 2h',
  Raope: 'RAOpe',
  'Work-permit': 'Permissão de Trabalho',
  'Integrity-form': 'Formulários de Integridade',
  'Daily-allocation': 'Alocação Diária',
  Piop: 'Planejamento Integrado de Operações',
  Priority: 'Comitê de Priorização',
  'Weekly-report': 'Avanço de Pendências',
  'Finished-piop': 'Atividades Finalizadas',
  'Refresh-times': 'Atualização das Views',
  'Bdo-ts': 'Tucano Sul',
  Consolidated: 'Slickline Consolidado',
  Dailytasks: 'Programação Diária',
  Bdosptintervention: 'Boletim Diário de Intervenção',
  'Intervention-panel': 'Painel de Intervenções',
  'Scheduled-tests': 'Testes Programados',
  'Final-drilling-report': 'Relatório Final de Perfuração',
  'Register-spt': 'Intervenções Cadastradas',
  'Intervention-report': 'Relatório Diário de Intervenções',
  'Intervention-final-report': 'Relatório Final de Intervenção'
};

export { translationBreadCrumbs };
