import { InteractionRequiredAuthError } from '@azure/msal-browser';
import moment from 'moment';
import { msalInstance } from '../main';

export const checkSSO = async () => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }

  // Refresh the token if it will expire in 5 minutes
  const validToken =
    account?.idTokenClaims?.exp &&
    account.idTokenClaims.exp > moment().unix() + 300;

  if (!validToken) {
    throw new InteractionRequiredAuthError();
  }
};
