import { Column } from 'react-table';
import { tablefy } from '../../utils/tablesUtils';

const items: any = {
  'Grupo do Equipamento': '3236',
  'Tipo de Equipamento': '3238',
  Modelo: '3239',
  Descrição: '3210',
  Quantidade: '3240',
  Estado: '3241',
  'Comprimento (m)': '3242',
  'Profundidade (m)': '3243',
  'OD (pol.)': '3244',
  'ID (pol.)': '3245',
  Fabricante: '3246'
};

const bdoSptIntEquipmentCols = (fourthary: boolean): Column[] =>
  tablefy(
    items,
    undefined,
    ['Quantidade', '(m)'],
    undefined,
    undefined,
    undefined,
    undefined,
    fourthary
  );

export default bdoSptIntEquipmentCols;
