import axios from 'axios';
import { getUserTokenToApiRequest } from './msal.services';

const api = axios.create({
  baseURL: import.meta.env.VITE_PORTAL_URL,
  headers: {
    'Content-type': 'application/json'
  }
});

api.interceptors.request.use(async (config: any) => {
  return getUserTokenToApiRequest(config);
});

export default api;
