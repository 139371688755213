import {
  CustomRedemineIssuesResponse,
  GetCustomFieldsResponse,
  RedmineIssueResponse,
  RedmineIssuesResponse
} from '../types/redmine';
import api from './apiPortal';

export const getIssuesRedmine = async (params: Record<string, any>) =>
  await api.get<RedmineIssuesResponse>('oilwells', {
    params
  });

export const getIssuesRedmine2 = async <T extends RedmineIssuesResponse>(
  params: Record<string, any>
) =>
  await api.get<T>('oilwells/relations', {
    params
  });

export const getIssueRedmine = async (id: string | number) =>
  (
    await api.get<RedmineIssueResponse>(`oilwells/${id}`, {
      params: { include: 'journals,relations' }
    })
  ).data;

export const getIssuesRedmineByIds = async (
  ids: number[],
  cf_ids: number[],
  sort_cf_id: number
) =>
  (
    await api.get<CustomRedemineIssuesResponse>(`oilwells`, {
      params: {
        ids,
        cf_ids,
        sort_cf_id,
        route: 'issues_custom_api'
      }
    })
  ).data;

export const getIssuesRelations = async (
  ids: number[],
  filter_status: number[]
) =>
  (
    await api.get('oilwells', {
      params: {
        route: 'relations_api',
        issue_ids: ids,
        status_ids: filter_status
      }
    })
  ).data;

export const getCustomFields = async (ids: (number | string)[]) =>
  (
    await api.get<GetCustomFieldsResponse>('oilwells', {
      params: {
        route: 'get_custom_fields',
        ids
      }
    })
  ).data;
