import { LabelProps } from '@/components/atoms/Label';
import { twMerge } from 'tailwind-merge';

export default function Label2(props: LabelProps) {
  return (
    <h1
      className={twMerge(
        'text-xl text-primary font-bold bg-[#F6F8FF] p-2',
        props.className
      )}
    >
      {props.name}
    </h1>
  );
}
