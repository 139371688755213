import Checkbox from '@/components/atoms/Checkbox';
import EmptyMessage from '@/components/atoms/EmptyMessage';
import H1 from '@/components/atoms/H1';
import Modal from '@/components/atoms/Modal';
import Spinner from '@/components/atoms/Spinner';
import { TableGrid } from '@/components/atoms/TableGrid';
import TabsItem from '@/components/atoms/TabsItem';
import { getPressureAndInjection } from '@/services/bdos.services';
import { getGrids } from '@/services/grids.services';
import { useQuery } from '@tanstack/react-query';
import { Chart, ChartOptions, registerables } from 'chart.js';
import {
  format,
  isToday,
  parse,
  parseISO,
  startOfHour,
  subDays
} from 'date-fns';
import { memo, useCallback, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useFormContext } from 'react-hook-form';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa6';
import { IoClose } from 'react-icons/io5';
import { Column } from 'react-table';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';
import { Header } from '../../../components/atoms/table/Header';
import { TooltipCardItem } from './alerts';
import equipActivityCols from '../equipActivity';
Chart.register(...registerables);

const numberFormatter = new Intl.NumberFormat('pt-BR', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

const numberFormatter2Decimal = new Intl.NumberFormat('pt-BR', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

export const PressureAlertCell = ({
  value,
  row: { index, original },
  column: { id },
  className,
  hideNumber = false
}: any) => {
  const classes: Record<string, { card: string; text: string }> = {
    1: { card: 'bg-red/25 text-red', text: 'text-red' },
    2: { card: 'bg-[#FFF069]/50 text-[#AD953C]', text: 'text-[#AD953C]' },
    3: { card: 'bg-[#10AE88]/25 text-[#0C8A6C]', text: 'text-[#0C8A6C]' }
  };

  const currentClasses = classes[value?.criticity];

  let formattedDate = '';
  if (value?.latest_hour) {
    const parsedDate = parse(
      value?.latest_hour?.split?.('+')?.[0],
      'yyyy-MM-dd HH:mm:ss',
      new Date()
    );
    formattedDate = format(parsedDate, 'dd/MM/yyyy HH:mm');
  }

  const currentDay = parseISO(original?.day);
  const currentHour = startOfHour(new Date());
  // O final do periodo anterior é o dia anterior ao dia atual
  const finalPreviousPeriodDate = subDays(currentDay, 1);
  const initialPreviousPeriodDate = subDays(
    finalPreviousPeriodDate,
    original?.interval - 1
  );

  const AlertCard = ({ className }: { className?: string }) => {
    return (
      <div
        className={twMerge(
          'flex flex-row gap-0.5 rounded px-1 py-1 min-w-16 items-center justify-center flex-none',
          currentClasses?.card,
          !value?.percentage && !hideNumber ? 'hidden' : '',
          className,
          hideNumber ? 'min-w-min' : ''
        )}
        data-tooltip-id={`alert-${id}${index}`}
      >
        {!value?.percentage ? (
          <span className="text-xl w-3 h-2 pb-3 pr-2 pl-px leading-3 ">•</span>
        ) : value?.percentage > 0 ? (
          <FaArrowUp size={12} className={currentClasses?.text} />
        ) : (
          <FaArrowDown size={12} className={currentClasses?.text} />
        )}
        {!hideNumber && value?.percentage ? (
          <span
            className={twMerge(
              'font-bold text-[10px] pt-[1px]',
              currentClasses?.text
            )}
          >
            {`${
              Math.abs(value?.percentage) > 0 && Math.abs(value?.percentage) < 1
                ? '>'
                : ''
            } ${numberFormatter.format(
              Math.floor(Math.abs(value?.percentage))
            )}%`}
          </span>
        ) : null}
      </div>
    );
  };

  return (
    <div
      className={twMerge(
        'text-[#191919] text-xs whitespace-pre-wrap flex flex-row gap-2 items-center',
        className ?? ''
      )}
      key={`Cell${index}`}
    >
      <span className="mr-auto">
        {value?.new_value !== undefined
          ? numberFormatter2Decimal.format(value?.new_value)
          : '-'}
      </span>

      <AlertCard />

      <ReactTooltip
        id={`alert-${id}${index}`}
        place="bottom"
        variant="light"
        opacity={0.95}
        className="shadow-lg z-50"
      >
        <div className="">
          <span className="font-bold text-sm display flex gap-3 items-center h-8">
            Variação de Pressão: <AlertCard className="py-0.5" />
          </span>
          <div className="flex gap-4 my-2 ">
            <div className="flex flex-col gap-2">
              <TooltipCardItem
                label="Medição Acumulada do Dia:"
                value={
                  isToday(currentDay)
                    ? `${format(currentHour, 'HH')} hrs ;${format(currentHour, 'dd/MM/yyyy')}`
                    : format(currentDay, 'dd/MM/yyyy')
                }
              />
              <TooltipCardItem
                label="Data período anterior:"
                value={
                  finalPreviousPeriodDate
                    ? `${original?.interval != 1 ? `${format(initialPreviousPeriodDate, 'dd/MM')} - ` : ''}${format(finalPreviousPeriodDate, 'dd/MM/yyyy')}`
                    : finalPreviousPeriodDate
                }
              />
            </div>
            <div className="flex flex-col gap-2">
              <TooltipCardItem
                label="Média:"
                value={numberFormatter2Decimal.format(value?.new_value)}
              />

              <TooltipCardItem
                label="Média:"
                value={numberFormatter2Decimal.format(value?.old_value)}
              />
            </div>
          </div>
        </div>
      </ReactTooltip>
    </div>
  );
};

const MemoizedPressureAlertCell = memo(PressureAlertCell);

const pressureAlertCols: Column[] = [
  {
    Header: ({ rows }) => {
      const { getValues, setValue, register } = useFormContext();

      const handleToggleAll = useCallback(
        (isChecked: boolean) => {
          const values = getValues();

          rows.forEach(row => {
            const { oilwell, day, interval } = row.original as any;
            const fieldName = `${oilwell}/${day}/${interval}`;

            if (`remove/${fieldName}` in values) {
              setValue(
                `check/${fieldName}`,
                isChecked ? row.original : undefined
              );
              setValue(`remove/${fieldName}`, isChecked);
            }
          });
        },
        [rows, getValues, setValue]
      );
      const rowId = `remove-all/${(rows?.[0]?.original as any).oilwell}/${(rows?.[0]?.original as any).day}/${(rows?.[0]?.original as any).interval}`;
      return (
        <div className="px-3">
          <Checkbox
            key={rowId}
            {...register(rowId, {
              onChange: e => handleToggleAll(e.target.checked)
            })}
          />
        </div>
      );
    },
    accessor: 'check',
    Cell: e => {
      const { register, setValue } = useFormContext();
      const { oilwell, day, interval } = e.row.original as any;
      const rowId = `${oilwell}/${day}/${interval}`;
      return (
        <Checkbox
          key={rowId}
          {...register(`remove/${rowId}`)}
          onChange={event =>
            setValue(
              `check/${rowId}`,
              event.target.checked ? e.row.original : false,
              {
                shouldDirty: true,
                shouldValidate: true
              }
            )
          }
        />
      );
    },
    width: '0.1%'
  },
  {
    Header: () => (
      <Header className="h-12 items-start text-start" text={'Poço'} id="poço" />
    ),
    accessor: 'oilwell',
    minWidth: 120,
    Cell: e => {
      const [modalOpen, setModalOpen] = useState(false);
      const [tab, setTab] = useState(0);
      const [page, setPage] = useState(1);
      const perPage = 25;
      const oilwell = (e.row.original as any).oilwell;

      const { data: testEquips, isLoading: equipsIsLoading } = useQuery(
        ['TestEquips', oilwell, page],
        () =>
          getGrids(oilwell as string, 'grid_equip', undefined, page, perPage),
        {
          staleTime: 1000 * 60,
          cacheTime: 1000 * 60,
          enabled: tab === 1
        }
      );

      const filterButtons = ['Pressões', 'Atividades Operacionais'].map(
        (it, idx) => ({
          label: it,
          onClick: () => {
            setTab(idx);
          }
        })
      );

      const handleGetPressures = async () => {
        const response = await getPressureAndInjection(oilwell, 'pressures');

        return response.data
          .filter((it: any) => it.Hora || it.hora2)
          .map((it: any) => ({
            ...it,
            Hora: it.Hora
              ? format(new Date(it.Hora.split('+')[0]), 'dd/MM/yyyy HH:mm')
              : it.hora2
                ? it.hora2.includes(' ')
                  ? it.hora2
                  : format(new Date(it.hora2.split('+')[0]), 'dd/MM/yyyy HH:mm')
                : null
          }));
      };

      const { data, isLoading, isFetching } = useQuery(
        ['PressuresOilwell', oilwell],
        () => handleGetPressures(),
        {
          staleTime: 1000 * 60,
          cacheTime: 1000 * 60,
          refetchOnMount: true,
          refetchOnWindowFocus: false,
          enabled: oilwell != undefined && modalOpen && tab === 0
        }
      );

      const formattedData = data
        ? data
            ?.map((it: any) => ({
              pcoluna:
                it.pcoluna !== '-'
                  ? Number(it.pcoluna?.replaceAll(',', '.'))
                  : null,
              plinha:
                it.plinha !== '-'
                  ? Number(it.plinha?.replaceAll(',', '.'))
                  : null,
              panulara:
                it.panulara !== '-'
                  ? Number(it.panulara?.replaceAll(',', '.'))
                  : null,
              panularb:
                it.panularb !== '-'
                  ? Number(it.panularb?.replaceAll(',', '.'))
                  : null,
              panularc:
                it.panularc !== '-'
                  ? Number(it.panularc?.replaceAll(',', '.'))
                  : null,
              tlinha:
                it.tlinha !== '-'
                  ? Number(it.tlinha?.replaceAll(',', '.'))
                  : null,
              Data: it.Data
            }))
            .reverse()
        : [];

      const chartData = {
        labels: formattedData.map((it: any) =>
          format(new Date(it.Data?.replaceAll('-', '/')), 'dd/MM/yy')
        ),
        datasets: [
          {
            label: 'Pressão da coluna (kgf/cm²)',
            data: formattedData.map((it: any) => it.pcoluna),
            borderColor: '#193eb8',
            backgroundColor: '#193eb8',
            pointRadius: 0,
            tension: 0.1,
            fill: false
          },
          {
            label: 'Pressão da linha (kgf/cm²)',
            data: formattedData.map((it: any) => it.plinha),
            borderColor: '#001707',
            backgroundColor: '#001707',
            pointRadius: 0,
            tension: 0.1,
            fill: false
          },
          {
            label: 'Pressão da anular A (kgf/cm²)',
            data: formattedData.map((it: any) => it.panulara),
            borderColor: '#1298C7',
            backgroundColor: '#1298C7',
            pointRadius: 0,
            tension: 0.1,
            fill: false
          },
          {
            label: 'Pressão da anular B (kgf/cm²)',
            data: formattedData.map((it: any) => it.panularb),
            borderColor: '#754e0f',
            backgroundColor: '#754e0f',
            pointRadius: 0,
            tension: 0.1,
            fill: false
          },
          {
            label: 'Pressão da anular C (kgf/cm²)',
            data: formattedData.map((it: any) => it.panularc),
            borderColor: '#A5A5A5',
            backgroundColor: '#A5A5A5',
            pointRadius: 0,
            tension: 0.1,
            fill: false
          }
        ]
      };

      const options: ChartOptions<'line'> = {
        responsive: true,
        plugins: {
          title: {
            display: false
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              usePointStyle: true,
              pointStyle: 'circle',
              font: {
                family: 'Graphie'
              }
            }
          },
          tooltip: {
            mode: 'index', // Show tooltips for all datasets at the same index
            intersect: false, // Show tooltips even if not directly over the point
            callbacks: {
              title: function (items: any) {
                return `Data: ${items[0].label}`; // Show the x-axis label
              },
              label: function (item: any) {
                // Show dataset label and value
                return `${item.dataset.label}: ${new Intl.NumberFormat(
                  'pt-BR',
                  {
                    maximumFractionDigits: 2
                  }
                ).format(item.raw)}`;
              }
            }
          }
        },
        scales: {
          x: {
            ticks: {
              autoSkip: true,
              maxTicksLimit: 12,
              color: '#191919',
              font: {
                size: 10,
                family: 'Graphie'
              }
            },
            grid: {
              display: false
            }
          },
          y: {
            ticks: {
              autoSkip: true,
              maxTicksLimit: 7,
              color: '#191919',
              font: {
                size: 12,
                family: 'Graphie'
              }
            },
            title: {
              display: true,
              text: 'Pressões e Temperaturas',
              font: {
                size: 14,
                family: 'Graphie',
                weight: 'bolder'
              }
            }
          }
        }
      };

      return (
        <div className="whitespace-nowrap ">
          <button
            className="text-[#191919] hover:underline text-xs"
            onClick={() => setModalOpen(true)}
            type="button"
          >
            {e.value}
          </button>

          {(e.row.original as any).from_pi ? (
            <span className="bg-[#D7DFFF] text-[#193CB9] rounded ml-2 p-0.5 text-xs">
              PI
            </span>
          ) : null}
          {modalOpen ? (
            <Modal
              isOpen={modalOpen}
              setIsOpen={setModalOpen}
              titleClassName="text-primary"
              className="max-w-[800px]"
            >
              <div className="px-4 py-3">
                <div className="flex w-full justify-between mb-2">
                  <div className="flex gap-2 items-center">
                    <H1 className="mb-0">{oilwell}</H1>
                    {Object.values(filterButtons).map((it, idx) => (
                      <TabsItem key={it.label} {...it} menu={tab} idx={idx} />
                    ))}
                  </div>

                  <div className="flex gap-2 items-center">
                    <a
                      className="rounded-full hover:bg-slate-50 p-2 text-gray/80 hover:text-gray"
                      href={`/home/oilwell-info/${oilwell}/${tab === 0 ? 'pressures' : 'equips'}`}
                      target="_blank"
                    >
                      <FaExternalLinkAlt />
                    </a>

                    <IoClose
                      size={20}
                      className="ml-auto text-black/70 hover:cursor-pointer"
                      onClick={() => setModalOpen(false)}
                    />
                  </div>
                </div>
                {tab === 0 && (
                  <div className="bg-white min-h-[350px] flex-1 px-2 rounded-2xl overflow-auto my-2">
                    {(isLoading || isFetching) && !data ? (
                      <div className="flex justify-center items-center h-full">
                        <Spinner />
                      </div>
                    ) : data?.length == 0 ? (
                      <EmptyMessage message="Sem dados de testes disponíveis para esse poço" />
                    ) : (
                      <Line data={chartData} options={options as any} />
                    )}
                  </div>
                )}
                {tab === 1 && (
                  <div>
                    {equipsIsLoading && <Spinner className="ml-2" size={24} />}
                    {testEquips?.equipFiltered && (
                      <TableGrid
                        cols={equipActivityCols}
                        tableContainerClassName="max-h-[calc(80vh-100px)] min-h-[350px]"
                        data={
                          testEquips?.equipFiltered
                            ? testEquips?.equipFiltered
                            : []
                        }
                      />
                    )}
                  </div>
                )}
              </div>
            </Modal>
          ) : null}
        </div>
      );
    },
    width: '12%'
  },
  {
    Header: () => (
      <Header
        className="h-12 items-start text-start"
        text={'Fluido'}
        id="Fluido"
      />
    ),
    accessor: 'fluid',
    Cell: DefaultCell,
    width: '12%'
  },
  {
    Header: () => (
      <Header
        className="h-12 items-start text-start"
        text={'Método de Elevação'}
        id="elevationMethod"
      />
    ),
    accessor: 'elevation_method',
    Cell: DefaultCell,
    width: '12%'
  },
  {
    Header: () => (
      <Header
        className="h-12 items-start text-start"
        text={`Pressão de Cabeça (kgf/cm²)`}
        id="head_pressure"
        info={` Vermelho: > 20% de variação
Amarelo: entre 10 a 20% de variação 
Verde: < 10% de variação`}
      />
    ),
    accessor: 'head_pressure',
    Cell: MemoizedPressureAlertCell,
    minWidth: 145
  },
  {
    Header: () => (
      <Header
        className="h-12 items-start text-start"
        text={`Pressão de Linha 
           (kgf/cm²)`}
        id="line_pressure"
        info={`Vermelho: > 10% de variação
Amarelo: entre 5 a 10% de variação 
Verde: < 5% de variação`}
      />
    ),
    accessor: 'line_pressure',
    Cell: MemoizedPressureAlertCell,
    minWidth: 145
  },
  {
    Header: () => (
      <Header
        className="h-12 items-start text-start"
        text={`Pressão de Anular A 
           (kgf/cm²)`}
        id="annular_pressure"
        info={`Vermelho: >= 20% de variação
Amarelo: entre 10 a 20% de variação 
Verde: < 10% de variação`}
        infoClassName={'ml-0'}
      />
    ),
    accessor: 'annular_pressure',
    Cell: MemoizedPressureAlertCell,
    minWidth: 145
  },
  {
    Header: () => (
      <Header
        className="h-12 items-start text-start"
        text={`Temperatura 
de Cabeça (°C)`}
        id="head_temperature"
        info={`Vermelho: >= 5ºC de variação
Amarelo: entre  2ºC a 5ºC de variação 
Verde: <= 2ºC de variação`}
      />
    ),
    accessor: 'head_temperature',
    Cell: MemoizedPressureAlertCell,
    minWidth: 145
  },
  {
    Header: () => (
      <Header
        className="h-12 items-start text-start"
        text={`Temperatura 
de Linha (°C)`}
        id="line_temperature"
        info={`Vermelho: >= 5 ºC de variação
Amarelo: entre 2 ºC a 5 ºC de variação 
Verde: <= 2 ºC de variação`}
      />
    ),
    accessor: 'line_temperature',
    Cell: MemoizedPressureAlertCell,
    minWidth: 145
  }
];

export default pressureAlertCols;
