import { useState } from 'react';
import { HiOutlinePlusSm } from 'react-icons/hi';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import Button from '../../components/atoms/Button';
import InterventionPanelCard, {
  InterventionPanelCardRequestData
} from '../../components/molecules/InterventionPanelCard';
import InterventionPanelModalItem from '../../components/molecules/InterventionPanelModalItem';
import { KanbanPhase } from '../../components/molecules/KanbanPhase';
import { SideFilterSearchParams } from '../../components/molecules/SideFilterSearchParams';
import { InterventionPanelFilters } from '../../constants/filters/InterventionPanelFilters';
import { useParams } from '../../hooks/Common/useParams';
import { getInterventions } from '../../services/bdos.services';
import { interventionPanelFilterAtom } from '../../state/interventionPanel.filter.atom';
import ActiveLink from '@/components/atoms/ActiveLink';

export const SPT_LIST = [
  //'SPT-010', //Desativada
  'SPT-030',
  'SPT-061',
  'SPT-108',
  'SPT-112',
  'SPT-128',
  'SPT-145',
  'SPT-146',
  'SPT-161',
  'UNP-BHP'
];

const InterventionPanelCache = 'interventionPanel';

export const InterventionPanel = () => {
  const { setParam, params } = useParams();
  const startDate = params.get('startDate');
  const [filters, setFilters] = useRecoilState(interventionPanelFilterAtom);
  const [usedFilters, setUsedFilters] = useState({
    ...filters,
    startDate: params.get('startDate')
  });

  const handleGetOperations = (probe: string) => async (page: number) => {
    try {
      return await getInterventions(probe, usedFilters, page);
    } catch (e: any) {
      console.error(e);
      toast.error('Erro ao buscar operações');
    }
  };

  const createCards = (props: InterventionPanelCardRequestData) => (
    <InterventionPanelCard
      key={props.id}
      interventionCode={props.interventionCode}
      data={{
        ...props
      }}
      id={props.id}
      late={props.late}
    />
  );

  const createModalItens = (props: InterventionPanelCardRequestData) => (
    <InterventionPanelModalItem
      key={props.id}
      interventionCode={props.interventionCode}
      data={{
        ...props
      }}
      id={props.id}
      late={props.late}
    />
  );

  return (
    <>
      <div className="w-full h-[calc(100%-45.6px)] flex flex-col relative">
        <div className="flex justify-end gap-2 absolute right-[240px] -top-10">
          <ActiveLink className="w-fit" to={'register-spt'}>
            <Button
              className="w-full"
              title="Intervenções Cadastradas"
              type="button"
            />
          </ActiveLink>
        </div>

        <div className="flex justify-end gap-2 absolute right-12 -top-10">
          <a
            className="w-fit"
            href={`${
              import.meta.env.VITE_REDMINE_URL
            }/projects/boletim-diario-de-operacoes-intervencao/issues/new?issue[tracker_id]=57`}
            target="_blank"
          >
            <Button
              className="w-full"
              title="Nova Intervenção"
              type="button"
              icon={<HiOutlinePlusSm className="w-5 h-5" color="white" />}
            />
          </a>
        </div>

        <div className="flex justify-end gap-2 absolute right-[465px] -top-10">
          <ActiveLink className="w-fit" to={'intervention-report'}>
            <Button className="w-full" title="RDI" type="button" />
          </ActiveLink>
        </div>

        <section className="flex-1 relative grid grid-cols-5 grid-rows-2 gap-4 mt-4 min-h-0 min-w-0">
          {SPT_LIST.map((spt, idx) => (
            <KanbanPhase
              key={spt}
              cacheName={InterventionPanelCache}
              title={`${idx + 1}. ${spt}`}
              filters={usedFilters}
              getOperations={handleGetOperations(spt)}
              createCards={createCards}
              createModalItens={createModalItens}
              emptyMessage="Sem poços alocados"
            />
          ))}
        </section>
      </div>

      <SideFilterSearchParams
        atom={interventionPanelFilterAtom}
        filters={InterventionPanelFilters}
        filterValues={filters}
        setUsedFilters={setUsedFilters}
        dateSearchParams={[
          {
            name: 'Data de Início',
            value: startDate,
            keyValue: 'startDate'
          }
        ]}
      />
    </>
  );
};
