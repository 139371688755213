import { useQuery } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useRecoilValue } from 'recoil';
import EmptyMessage from '../../components/atoms/EmptyMessage';
import { PageWrapper } from '../../components/atoms/PageWrapper';
import Spinner from '../../components/atoms/Spinner';
import PageNavigation from '../../components/molecules/PageNavigation';
import SideFilter from '../../components/molecules/SideFilter';
import Table from '../../components/organisms/Table';
import { piopStatus } from '../../constants/enums/status';
import { PiopFilters } from '../../constants/filters/PiopFilters';
import weekly from '../../constants/tableColumns/piop/weekly';
import { getAllPiop, getPiop } from '../../services/bdos.services';
import { PiopFiltersAtom } from '../../state/piop.filters';
import { getFieldByArray } from '../../utils/tablesUtils';

export const FinishedPiop = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const filtersState = useRecoilValue(PiopFiltersAtom);
  const [filteredData, setFilteredData] = useState(filtersState);
  const perPage = 30;

  const handleFilter = (isClearing?: boolean) => {
    !isClearing
      ? setFilteredData(filtersState)
      : setFilteredData({
          station: [],
          discipline: [],
          status: [],
          priority: [],
          operation: '',
          equipment: [],
          order: '',
          timeRange: {
            endDate: undefined,
            startDate: undefined
          },
          scheduledDate: {
            endDate: undefined,
            startDate: undefined
          }
        });
  };

  const formatData = (it: any) => ({
    issue_id: it.id,
    order: getFieldByArray(it.custom_fields, 2320),
    discipline: getFieldByArray(it.custom_fields, 2339),
    equipment: getFieldByArray(it.custom_fields, 2336),
    description: getFieldByArray(it.custom_fields, 2324),
    descriptionOp: getFieldByArray(it.custom_fields, 2414),
    startScheduled: getFieldByArray(it.custom_fields, 2332),
    wellAffected: getFieldByArray(it.custom_fields, 2423),
    priority: getFieldByArray(it.custom_fields, 2343)
  });

  const getData = async () => {
    const data = await getPiop(perPage, (currentPage - 1) * perPage, {
      ...filteredData,
      status: [piopStatus['Simultaneidade Aprovada'] + '']
    });

    const response = {
      ...data,
      data: data?.issues.map(formatData)
    };

    return response;
  };

  const getAllData = async () => {
    if (!issues) throw new Error('No data');
    const data = await getAllPiop(
      {
        ...filteredData,
        status: [piopStatus['Simultaneidade Aprovada'] + '']
      },
      issues.total_count
    );

    const response = {
      ...data,
      data: data?.issues.map(formatData)
    };

    return response;
  };

  const { data: issues, isLoading } = useQuery({
    queryFn: async () => getData(),
    queryKey: ['Piop-data-finish', filteredData, currentPage]
  });

  const { data: issuesMax, isLoading: isLoadingMax } = useQuery({
    queryFn: async () => getAllData(),
    queryKey: ['Piop-all-finish', filteredData],
    enabled: !!issues
  });

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  return (
    <>
      <PageWrapper>
        <div className="bg-white rounded-[10px] py-4 px-6 h-full mt-4">
          <h1 className="text-gray font-bold text-lg mb-4 w-full flex justify-between">
            Relatório Semanal de Atividades Realizadas
            <button
              className="text-base font-medium px-2 py-1 rounded border no-print disabled:opacity-30 text-primary border-primary"
              onClick={handlePrint}
              disabled={isLoadingMax}
            >
              Imprimir
            </button>
          </h1>

          <div className="h-full max-h-[calc(100vh-250px)] pb-6 overflow-y-scroll scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full">
            {isLoading ? (
              <Spinner size={36} />
            ) : !issues?.data?.length ? (
              <EmptyMessage message="Nenhum dado foi encontrado" />
            ) : (
              <Table
                key="flowRate"
                columns={weekly}
                headerClassName="sticky top-0 z-10 bg-white"
                data={issues.data}
              />
            )}
          </div>

          {!!issues && (
            <PageNavigation
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              className="mt-3"
              totalPages={Math.ceil(issues?.total_count / perPage)}
              perPage={perPage}
            />
          )}
        </div>
      </PageWrapper>

      <SideFilter
        atom={PiopFiltersAtom}
        filters={PiopFilters}
        applyChanges={handleFilter}
        floatButtonClassName="top-28"
      />

      <div className="only-print hidden">
        <div
          className="bg-white rounded-[10px] py-4 px-6 h-full mt-4"
          ref={componentRef}
        >
          <h1 className="text-gray font-bold text-lg mb-4 w-full flex justify-between">
            Relatório Semanal de Atividades Realizadas
          </h1>

          <div className="h-full pb-6 overflow-y-scroll scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full">
            {isLoading ? (
              <Spinner size={36} />
            ) : !issuesMax?.data?.length ? (
              <EmptyMessage message="Nenhum dado foi encontrado" />
            ) : (
              <Table key="flowRate" columns={weekly} data={issuesMax?.data} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
