import { Link } from 'react-router-dom';
import LateClock, { LateOptions } from '../atoms/LateClock';
import MenuOptions from '../atoms/MenuOptions';
import {
  InterventionPanelCardContent,
  InterventionPanelCardData,
  InterventionStatus
} from './InterventionPanelCard';

const InterventionPanelModalItem = ({
  id,
  interventionCode,
  data,
  late
}: InterventionPanelCardData) => {
  const isLate = late === LateOptions.NotLate ? false : true;
  const seeReportURL =
    data.status === InterventionStatus.Concluded
      ? `/home/intervention-panel/intervention-final-report/${id}`
      : `/home/intervention-panel/${id}`;
  const editIssueURL = `${import.meta.env.VITE_REDMINE_URL}/issues/${id}`;
  return (
    <div className="flex flex-row justify-stretch items-center shadow-light hover:outline hover:outline-blue-400 rounded-lg px-4 py-3 gap-2">
      <Link to={seeReportURL} target="_blank">
        <div className={`flex flex-row items-center gap-2`}>
          {data.status !== InterventionStatus.Qeued ? (
            <span className="font-bold text-[8px] text-gray/80 text-nowrap">
              {interventionCode}
            </span>
          ) : null}

          <InterventionPanelCardContent {...data} />
        </div>
      </Link>
      {isLate && <LateClock id={`${id}`} late={LateOptions.Late}></LateClock>}
      <MenuOptions
        nameId={`${id}-menu-options-kanban`}
        menuClassName="translate-x-[40px] translate-y-[3%]"
        buttonClassName="ml-auto"
        links={[
          { label: 'Ver', url: seeReportURL },
          { label: 'Editar', url: editIssueURL }
        ]}
      />
    </div>
  );
};

export default InterventionPanelModalItem;
