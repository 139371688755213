import { Column } from 'react-table';
import { DefaultCell } from '../../components/atoms/table/DefaultCell';

const bdoSptOperationsSummaryCols: Column[] = [
  {
    Header: () => <></>,
    accessor: '3335',
    Cell: props => (
      <DefaultCell className="uppercase text-body font-bold" {...props} />
    ),
    width: '20%'
  },
  {
    Header: () => <></>,
    accessor: '3336',
    Cell: DefaultCell,
    width: '80%'
  }
];

export default bdoSptOperationsSummaryCols;
