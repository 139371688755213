import { getAllOilwells } from '../../services/oilwells.services';

const oilwellTypeValues = ['Produtor', 'Injetor', 'NA'];

const fieldValues = [
  'Anambé',
  'Arapaçu',
  'Cidade de São Miguel dos Campos',
  'Conceição',
  'Fazenda Matinha',
  'Fazenda Santa Rosa',
  'Furado',
  'Paru',
  'Pilar',
  'Quererá',
  'São Miguel dos Campos'
];

const hubValues = [
  {
    label: 'Alagoas',
    value:
      'Anambé|Arapaçu|Cidade de São Miguel dos Campos|Furado|Paru|Pilar|São Miguel dos Campos'
  },
  {
    label: 'Tucano Sul',
    value: 'Conceição|Quererá|Fazenda Matinha|Fazenda Santa Rosa'
  }
];

const oilwellTestValues = [
  'ANP',
  'Operacional',
  'Rápido',
  'Conjugado',
  'Acompanhamento',
  'Não Informado'
];

const fluidTypeValues = ['Óleo', 'Água', 'GNA', 'NA'];

export const objectify = (item: string) => ({ label: item, value: item });

const oilwellType = oilwellTypeValues.map(objectify);

const oilwellStation = fieldValues.map(objectify);

const oilwellTest = oilwellTestValues.map(objectify);

const moveableTest = [
  { label: 'Sim', value: '1' },
  { label: 'Não', value: '0' },
  { label: 'NA', value: '!*' }
];

const oilwellFluidType = fluidTypeValues.map(objectify);

const getOilWellAsync = async (search: string) => {
  const oilwells = await getAllOilwells(0, 50, search);

  return oilwells?.data?.map((it: any) => ({
    value: it.name,
    label: it.name
  }));
};

export const workflowTestFilters = [
  {
    key: 'oilwell',
    name: 'Código de poço',
    values: [],
    asyncFn: getOilWellAsync
  },
  { key: 'oilwellType', name: 'Tipo de poço', values: oilwellType },
  { key: 'hub', name: 'Polo', values: hubValues },
  { key: 'station', name: 'Campo', values: oilwellStation },
  { key: 'testType', name: 'Tipo de teste', values: oilwellTest },
  { key: 'fluidType', name: 'Tipo de fluído', values: oilwellFluidType },
  { key: 'moveableTest', name: 'Teste Móvel', values: moveableTest }
];

export const scheduledTestsFilters = [
  { key: 'timeRange', name: 'Período', values: [] },
  {
    key: 'hub',
    name: 'Estação',
    values: [
      {
        label: 'Pilar',
        value: 'Pilar'
      },
      {
        label: 'Furado',
        value:
          'Anambé|Arapaçu|Cidade de São Miguel dos Campos|Furado|Paru|São Miguel dos Campos'
      },
      {
        label: 'Tucano Sul',
        value: 'Conceição|Quererá|Fazenda Matinha|Fazenda Santa Rosa'
      }
    ]
  },
  { key: 'field', name: 'Campo', values: oilwellStation },
  { key: 'testType', name: 'Tipo de teste', values: oilwellTest },
  { key: 'moveableTest', name: 'Teste Móvel', values: moveableTest }
];
